/* eslint-disable no-alert */
/* eslint-disable complexity */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@alphakits/ui/dist';
import { OpenapiOrderRO } from '@escapenavigator/types/dist/openapi/orders/openapi-order.ro';
import { OrderCancelReasonEnum } from '@escapenavigator/types/dist/order/enum/order-cancel-reson.enum';
import { getOrderCancelationDate } from '@escapenavigator/utils/dist/get-order-cancelation-date';
import {
    FineDescriptions,
    getOrderCancelationState,
} from '@escapenavigator/utils/dist/get-order-cancelation-state';
import { isBefore } from 'date-fns';

import styles from './index.module.css';

type Props = {
    order: OpenapiOrderRO;
    cancelOrder: () => void;
};

export const OrderCancelation: React.FC<Props> = ({ order, cancelOrder }) => {
    const { t } = useTranslation();

    const handleCancelOrder = () => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm(t('Точно отменяем'))) cancelOrder();
    };

    const { returnAmount, type, allowedCancelation } = getOrderCancelationState({
        date: order.utcDate,
        transactions: order.transactions,
        certificates: order.certificates,
        returnCertificates: false,
        slotDiscount: order.slotDiscount,
        cancelationReason: OrderCancelReasonEnum.CLIENT_SELF,
        payed: order.payed,
        minHoursForFreeCanceling: order.slot.rule.minHoursForFreeCanceling,
        cancelationRule: order.slot.rule.cancelationRule,
        cancelationAmount: order.slot.rule.cancelationAmount,
        total: order.total,
        minimalPrice: +Object.values(order.price)[0],
    });

    const { orderCancelationHumantDate, orderCancelationDate } = getOrderCancelationDate({
        utcDate: order.utcDate,
        timeZone: order.questroom.location.timeZone,
        minHoursForFreeCanceling: order.slot.rule.minHoursForFreeCanceling,
        cancelationRule: order.slot.rule.cancelationRule,
    });

    const canCancel =
        allowedCancelation &&
        (type === FineDescriptions.AUTO_RETURN || type === FineDescriptions.NO_FINE);

    const cancelationText = useMemo(() => {
        if (!canCancel && isBefore(orderCancelationDate, new Date(order.createdAt))) return t('Нельзя отменить2');

        if (!canCancel) return t('Нельзя отменить', { date: orderCancelationHumantDate });

        if (returnAmount) {
            return t('Можно отменить', {
                value: orderCancelationHumantDate,
                returnAmount: `${+(returnAmount / 100).toFixed(2)} ${order.currency}`,
            });
        }

        return t('Можно отменить без возврата', { value: orderCancelationHumantDate });
    }, [
        canCancel,
        order.currency,
        order.createdAt,
        orderCancelationDate,
        orderCancelationHumantDate,
        returnAmount,
        t,
    ]);

    return order.penalties?.length ? null : (
        <div>
            <Typography.Text view="caps">
                { cancelationText }.{ ' ' }
                { canCancel && (
                    <Button onClick={ handleCancelOrder } className={ styles.cancel } view="ghost">
                        { t('Отменить игру') }
                    </Button>
                ) }
            </Typography.Text>
        </div>
    );
};
