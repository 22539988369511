/* eslint-disable complexity */
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import styles from './index.module.css';

export const Booking: React.FC = () => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const params = useParams();

    const urlParams = new URLSearchParams(window.location.search);

    const lang = urlParams.get('lang');

    useEffect(() => {
        const script = document.createElement('script');

        script.src = `${process.env.REACT_APP_WIDGET_DOMAIN}/widget-loader.js`;
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div className={ styles.container }>
            <div
                data-widget-id={ params.id }
                data-language={ lang }
                data-theme="dark"
                data-widget-type="schedule"
            />
        </div>
    );
};
